import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { AnimationContext } from '../context/AnimationContext'

export default function CookiePolicyPage() {

	const { animationEnabled, setAnimationEnabled, setIsHomepage } = useContext( AnimationContext )

	useEffect(() => {
		setIsHomepage( false )
	}, [ setIsHomepage ] )

	// Disable the animation so it doesn't run if the user heads back to homepage
	useEffect(() => {
		if ( animationEnabled ) {
			setAnimationEnabled( false )
		}
	}, [ animationEnabled, setAnimationEnabled ] )

	const queryData = useStaticQuery(graphql`
		query CookiePolicyPageQuery {
			markdownRemark(fileAbsolutePath: { regex: "/cookie-policy.md/" }) {
				html
			}
		}
	`)

	return (
		<div className="article">
			<div className="article__wrapper wrapper">
				<h1 className="article__header">Cookie Policy</h1>

				<div className="article__body" dangerouslySetInnerHTML={{ __html: queryData.markdownRemark.html }} />
			</div>
		</div>
	)

}
